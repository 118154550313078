import React, {useEffect} from 'react';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';
import {useSelector} from 'react-redux';

import {
    Button, Icon, makeStyles
} from '@material-ui/core';

import {useRawApi} from '../api';
import {url_for} from '../routes';
import BasePage from '../components/BasePage';

import PccLoginButton from '../components/PccLoginButton';

const useStyles = makeStyles(theme => ({
    header: {
        'text-align': 'center',
    },
}));


export default function HomePage() {
    const api = useRawApi();
    const classes = useStyles();
    const authInfo = useSelector(fp.get('auth.maphabit'));

    useEffect(() => {
        if(!authInfo) {
            api.refresh();
        }
    }, [api, authInfo])

    return (
        <BasePage title="Maphabit / PointClickCare Integration Console">
            <header className={classes.header}>
                {authInfo
                    ? (
                        <div>
                            <h3>Welcome, {authInfo.first_name} {authInfo.last_name}!</h3>
                            <p>
                                This is the PointClickCare integration console.
                                You should find most of what you need linked from
                                the <R.Link to={url_for('facilityCollection')}>Facility Listing</R.Link>.
                            </p>
                            <Button variant="contained" color="primary"
                                startIcon={<Icon>business</Icon>} endIcon={<Icon>arrow_right</Icon>}
                                component={R.Link} to={url_for('facilityCollection')}
                            >
                                Visit the facility listing now
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <h3>You must be signed in to use this console.</h3>
                            <PccLoginButton />
                        </div>
                    )
                }
            </header>
        </BasePage>
    )
}