import React from 'react';

import BasePage from '../components/BasePage';
import FacilityTable from '../components/FacilityTable';


export default function HomePage() {
    return (
        <BasePage title="Facility Listing">
            <FacilityTable />
        </BasePage>
    );
}