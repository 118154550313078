import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Button} from '@material-ui/core';

import {useRawApi} from '../api';
import {useAsyncMemo} from '../utils';
import {url_for} from '../routes';

export default function AuthInfo() {
    const api = useRawApi();
    const authData = useSelector(fp.get('auth.maphabit'));
    const authorizeUrl = useAsyncMemo(api.arborian.authorizeUrl, [api]);

    if(fp.isEmpty(authData)) {
        return (
            <Button
                href={fp.getOr(null, 'href', authorizeUrl)}
                color="inherit"
            >
                Sign in
            </Button>
        )
    }
    return (
        <div>
            Welcome, {authData.username}!
            <Button color="inherit" onClick={api.logout}>Sign out</Button>
        </div>
    );
}
