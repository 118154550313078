import {useEffect, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import * as A from './actions';

export const once = f => {
    let cur = null;
    return async (...args) => {
        if(cur) {
            return await cur;
        } else {
            cur = f(...args);
            try {
                return await cur;
            } finally {
                cur = null;
            }
        }
    }
}


export const useAsyncCallback = (f, deps, errorValue) => {
    const dispatch = useDispatch();
    const wrapper = async (...args) => {
        try {
            return await f(...args);
        } catch(e) {
            dispatch(A.notify({
                type: 'error',
                message: e.toString(),
            }))
            return errorValue
        }
    }
    return useCallback(wrapper, deps);
}


export const useStorage = (storage, key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const svalue = storage.getItem(key);
            return svalue ? JSON.parse(svalue) : initialValue;
        } catch(error) {
            console.error('Error loading storage', error);
            return initialValue;
        }
    })

    const setValue = useCallback(
        value => {
            try {
                const valueToStore = (
                    value instanceof Function
                    ? value(storedValue)
                    : value
                );
                setStoredValue(valueToStore);
                storage.setItem(key, JSON.stringify(valueToStore));
            } catch(error) {
                console.error('Error saving storage', error);
            }
        },
        [storage, key, storedValue]
    )
    return [storedValue, setValue];
}

export const useAsyncMemo = (f, deps=[]) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState();
    useEffect(
        () => {
            f().then(setValue).catch(e => {
                console.error(e);
                dispatch(A.notify({
                    type: 'error',
                    message: e.toString(),
                }))

            })
        },
        deps  // eslint-disable-line
    )
    return value;
}