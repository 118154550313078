import React, {
    createContext, useEffect, useContext, useState, useMemo
} from 'react';
import {useDispatch} from 'react-redux';
import fp from 'lodash/fp';

import {history, url_for} from '../routes';
import * as A from '../actions';

import ArborianApi from './ArborianApi';
import MapHabitApi from './MapHabitApi';

export const Context = createContext();

export function Provider({children}) {
    const dispatch = useDispatch();

    const arborian = useMemo(() => new ArborianApi(dispatch, {
        name: 'mhmid_rtok.arborian',
        root: process.env.REACT_APP_ARBORIAN_API,
    }), [dispatch]);

    const maphabit = useMemo(() => new MapHabitApi(dispatch, {
        name: 'mhmid_rtok.maphabit',
        root: process.env.REACT_APP_MH_API,
    }), [dispatch]);

    const urlFor = async (name, params) => {
        try {
            return arborian.urlFor(name, params);
        } catch(e) {
            return maphabit.urlFor(name, params);
        }
    }

    const refresh = async () => {
        let arb = await arborian.refresh();
        if(arb) {
            let mh = await maphabit.refresh();
        }
    }

    const select = async (mhUser) => {
        let arb = await arborian.authData();
        let mh = await maphabit.select(mhUser);
    }

    const logout = () => {
        dispatch(A.logout());
        arborian.logout();
        maphabit.logout();
        history.push(url_for('home'));
    }

    const ensureAuthorized = async () => {
        let arb = await arborian.authData();
        if(arb) {
            let mh = await maphabit.authData();
            if(mh) {
                return {arborian: arb, maphabit: mh};
            }
        }
        this.logout();
        console.log('Unauthorized');
        history.push(url_for('home'));
    }

    return <Context.Provider value={{
        arborian, maphabit, urlFor, logout,
        select, refresh, ensureAuthorized,
    }}>{children}</Context.Provider>
}

export const useRawApi = () => useContext(Context);
export const useApi = name => {
    let [value, setValue] = useState(null);
    let api = useContext(Context);

    useEffect(
        () => {
            api.ensureAuthorized().then(
                () => setValue(api)
            )
        },
        [name, api]
    )
    return fp.get(name, value);
}

