import React from 'react';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import {
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails
} from '@material-ui/core';

import {useAsyncMemo} from '../utils';
import {useApi} from '../api';
import {url_for} from '../routes';

import BasePage from '../components/BasePage';
import PickListTable from '../components/PickListTable';


function PickList({title, ...props}) {
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary>
                {title}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <PickListTable title={title} {...props}/>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}


export default function PickListCollectionPage() {
    const api = useApi('arborian');
    const mh = useApi('maphabit');
    const {state} = R.useLocation();
    const {facId} = R.useParams();

    const fac = useAsyncMemo(async () => {
        if(fp.get('fac', state)) return state.fac;
        if(!api) return null;
        let resp = await api.fetch(api.urlFor('pcc2.facility', {facId}))
        return await resp.json();
    }, [state, api])

    const options = useAsyncMemo(async () => {
        if(!mh) return null;
        let resp = await mh.fetch(mh.urlFor('master_data'));
        let lists = await resp.json();
        let formatList = fp.transform((acc, val) => {acc[val.id] = val.title});
        let result = fp.flow(
            fp.toPairs,
            fp.map(([key, value]) => ([key, formatList({}, value)])),
            fp.fromPairs
        )(lists.data);
        return result;
    }, [mh])

    return (
        <BasePage title={`Picklist mapping: ${fp.getOr('--', 'data.attributes.pcc.facilityName', fac)}`}>
            <p>Back to the <R.Link to={url_for('facilityCollection')}>facility listing</R.Link></p>
            <PickList fac={fac} options={options} pcc_name="race" mh_name='ethnicity' title='Ethnicity'/>
            <PickList fac={fac} options={options} pcc_name="language" mh_name='language' title='Language'/>
            <PickList fac={fac} options={options} pcc_name="prefix" mh_name='name_prefix' title='Name Prefix'/>
            <PickList fac={fac} options={options} pcc_name="suffix" mh_name='name_suffix' title='Name Suffix'/>
            <PickList fac={fac} options={options} pcc_name="contactRelationships" mh_name='relation' title='Relation'/>
        </BasePage>
    );
}