import React, {useEffect, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import MaterialTable from 'material-table';
import {url_for} from '../routes';
import {useApi} from '../api';

import EditField from './NullableTableEditField';

const AddressField = row => {
    let line2 = fp.get('attributes.pcc.addressLine2', row);
    return (
        <div>
            {fp.get('attributes.pcc.addressLine1', row)}
            {line2 && <span><br/>{line2}</span>}
        </div>
    )
}


const columns = [
    {
        title: 'Community ID', field: 'attributes.maphabit.mh_community_id', type: 'numeric',
    },
    {
        title: 'Autosync', field: 'attributes.maphabit.autosync', type: 'boolean'
    },
    {title: 'Code', field: 'attributes.pcc.facilityCode', type: 'numeric', editable: 'never'},
    {title: 'Name', field: 'attributes.pcc.facilityName', editable: 'never'},
    {title: 'Phone', field: 'attributes.pcc.phone', editable: 'never'},
    {title: 'Address', render: AddressField, editable: 'never'},
    {title: 'City', field: 'attributes.pcc.city', editable: 'never'},
    {title: 'State', field: 'attributes.pcc.state', editable: 'never'},
    {title: 'Zip', field: 'attributes.pcc.postalCode', editable: 'never'},
    {title: 'Time Zone', field: 'attributes.pcc.timeZone', editable: 'never'},
]


export default function FacilityTable() {
    const api = useApi('arborian');
    const authData = useSelector(fp.get('auth.maphabit'));
    const tableRef = useRef();

    const fetchData = useCallback(async () => {
        if(!api) return {data: [], page: 0, totalCount: 0};
        let resp = await api.fetch(api.urlFor('pcc2.facilityCollection'));
        let facs = await resp.json();
        console.log('Got facs', facs);

        let rv = {
            data: facs.data,
            page: 0,
            totalCount: facs.data.length,
        }
        return rv;
    }, [api], {data: [], page: 0, totalCount: 0})

    const refresh = useCallback(
        () => tableRef.current.onQueryChange(),
        [tableRef]
    );

    const onUnlinkFacility = async (ev, row) => {
        const body = fp.set(
            'attributes.maphabit',
            {'mh_community_id': null},
            {type: row.type, id: row.id}
        );
        console.log('got update', body.attributes.maphabit);
        let r = await api.fetch(row.links.self, {
            method: 'PATCH',
            body: JSON.stringify({data: body})
        })
        console.log('Got result', r)
        tableRef.current.onQueryChange();
    }

    const onLinkFacility = useCallback(async (e, row) => {
        const arb_auth = await api.authData();
        const mhUser = fp.find(
            mhUser => mhUser.user_id === authData.id,
            arb_auth.mh_users,
        )
        console.log('mhUser', mhUser, row);
        const body = fp.set(
            'attributes.maphabit.mh_community_id',
            mhUser.community_id,
            {type: row.type, id: row.id}
        );
        console.log('got update', body);
        let r = await api.fetch(row.links.self, {
            method: 'PATCH',
            body: JSON.stringify({data: body})
        })
        console.log('Got result', r)
        tableRef.current.onQueryChange();
    }, [api, authData]);

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [tableRef, api])

    const history = R.useHistory();

    return (
        <MaterialTable
            title="Facility Listing"
            columns={columns}
            data={fetchData}
            tableRef={tableRef}
            components={{EditField}}
            options={{
                search: false,
                sorting: false,
                paging: false,
            }}
            actions={[
                {icon: 'refresh', tooltip: 'Refresh', isFreeAction: true, onClick: refresh},
                row => ({
                    icon: 'link', tooltip: 'Link facility', onClick: onLinkFacility,
                    hidden: row.attributes.maphabit.mh_community_id !== null
                }), row => ({
                    icon: 'person', tooltip: 'View patients', onClick: () => {
                        history.push(url_for('patientCollection', {facId: row.attributes.pcc.facId}))
                    },
                    hidden: row.attributes.maphabit.mh_community_id === null
                }), row => ({
                    icon: 'list', tooltip: 'View pick lists', onClick: () => {
                        history.push(url_for('pickListCollection', {facId: row.attributes.pcc.facId}))
                    },
                    hidden: row.attributes.maphabit.mh_community_id === null
                }), row => ({
                    icon: 'link_off', tooltip: 'Unlink facility', onClick: onUnlinkFacility,
                    hidden: row.attributes.maphabit.mh_community_id === null
                }),
            ]}
        />
    )
}