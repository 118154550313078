import React from 'react';

import BasePage from '../components/BasePage';
import MaphabitLoginForm from '../components/MapHabitLoginForm';

export default function LoginPage() {
    return (
        <BasePage title="LoginPage">
            <MaphabitLoginForm />
        </BasePage>
    )
}