import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core';

import AppBar from './AppBar';
import NavDrawer from './NavDrawer';
import Notifications from './Notifications';

const useStyles = makeStyles(theme => ({
    root: {
        '& h1': theme.typography.h1,
        '& h2': theme.typography.h2,
        '& h3': theme.typography.h3,
        '& h4': theme.typography.h4,
        '& h5': theme.typography.h5,
        '& h6': theme.typography.h6,
        '& p': theme.typography.body1,
    },
}));

export default function BasePage({title, children}) {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <div className={classes.root}>
            <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)}/>
            <AppBar title={title} onToggleDrawer={() => setDrawerOpen(!drawerOpen)}/>
            <div>{children}</div>
            <Notifications />
        </div>
    )
}