export const LOGIN_ARBORIAN = 'LOGIN_ARBORIAN';
export const LOGIN_MAPHABIT = 'LOGIN_MAPHABIT';
export const LOGOUT = 'LOGOUT';

export const NOTIFY = 'NOTIFY';
export const NOTIFY_CLEAR = 'NOTIFY_CLEAR';

export const loginArborian = payload => ({type: LOGIN_ARBORIAN, payload});
export const loginMapHabit = payload => ({type: LOGIN_MAPHABIT, payload});
export const logout = () => ({type: LOGOUT, payload: null});

export const notify = payload => ({type: NOTIFY, payload});
export const notifyClear = () => ({type: NOTIFY_CLEAR});
