import React from 'react';
import {makeStyles} from '@material-ui/core';

import BasePage from '../components/BasePage';
import AuthorizedUserTable from '../components/AuthorizedUserTable';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
    },
    table: {
        minWidth: 640,
    },
}))

export default function UserCollectionPage() {
    const classes = useStyles();
    return (
        <BasePage title="Authorized Users">
            <div className={classes.container}>
                <div className={classes.table}>
                    <AuthorizedUserTable />
                </div>
            </div>
        </BasePage>
    );
}