import fp from 'lodash/fp';

import * as A from '../actions';

const initialState = {
    auth: null,
    notifications: [],
};

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case A.LOGIN_ARBORIAN:
            return fp.set('auth.arborian', action.payload, state)
        case A.LOGIN_MAPHABIT:
            return fp.set('auth.maphabit', action.payload, state)
        case A.LOGOUT:
            return fp.set('auth', null, state);
        case A.NOTIFY:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    action.payload,
                ]
            };
        case A.NOTIFY_CLEAR:
            return {
                ...state,
                notifications: fp.drop(1, state.notifications)
            }
        default:
            return state;
    }
}