import React, {useState, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import fp from 'lodash/fp';

import {
    Snackbar, SnackbarContent, IconButton, 
    colors, makeStyles
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import * as A from '../actions';

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: colors.green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: colors.amber[700],
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing(1),
    }    
}));

function Notification({notification, onClose, ...other}) {
    const classes = useStyles();
    if(!notification) return <span/>;
    const typeName = fp.get('type', notification);
    const notificationIcon = (
        typeName === 'success' ? <I.CheckCircle className={classes.icon}/>
        : typeName === 'error' ? <I.Error className={classes.icon}/>
        : typeName === 'info' ? <I.Info className={classes.icon}/>
        : typeName === 'warning' ? <I.Warning className={classes.icon}/>
        : null
    )
    return (
        <SnackbarContent
            className={classes[typeName]}
            aria-describedby="message-id"
            message={
                <span id="message-id" className={classes.message}>
                    {notificationIcon}
                    {fp.get('message', notification)}
                </span>}
            action={[
                <IconButton 
                    key="close"
                    color="inherit" onClick={onClose}
                >
                    <I.Close/>
                </IconButton>
            ]}
            {...other}
        />
    )
}

export default function Notifications() {
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch()
    const handleClose = useCallback(
        () => {
            dispatch(A.notifyClear());
            setOpen(false);
        },
        [dispatch, setOpen]
    )
    const notification = useSelector(state => fp.head(state.notifications));

    return (
        <Snackbar
            open={open} onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Notification notification={notification} onClose={handleClose}/>
        </Snackbar>
    )
}