import fp from 'lodash/fp';
import moment from 'moment';

import {history, url_for} from '../routes';
import {once} from '../utils';
import * as A from '../actions';

import Api from './Api';

export default class ArborianApi extends Api {

    constructor(dispatch, {root, name}) {
        super(dispatch);
        this._root = root;
        this._name = name;
        this._authData = null;   // cached version so we don't need to use the selector
        this._directory = null;
    }

    get _refresh_token() {
        try {
            return sessionStorage.getItem(this._name);
        } catch(error) {
            console.error('Error loading storage', error);
            return null;
        }
    }

    set _refresh_token(value) {
        if(value) {
            sessionStorage.setItem(this._name, value);
        } else {
            sessionStorage.removeItem(this._name, value);
        }
    }

    authorizeUrl = once(async () => {
        let base = await this.urlFor('oauth.authorize');
        let url = new URL(base);
        url.searchParams.set('response_type', 'code');
        url.searchParams.set('redirect_uri', new URL(
            url_for('callback'), window.location
        ).href);
        url.searchParams.set('state', 'Georgia');
        return url;
    })

    updateHeaders(headers) {
        headers = super.updateHeaders(headers);
        if(this._authData) {
            headers = {
                'Authorization': `Bearer ${this._authData.access_token}`,
                ...headers
            }
        }
        return headers;
    }

    directory = once(async () => {
        if(this._directory === null) {
            try {
                let resp = await fetch(this._root);
                let data = await resp.json();
                this._directory = data.data;
            } catch(e) {
                console.error('Error getting directory', e);
            }
        }
        return this._directory;
    })

    ensureAuthorized = once(async () => {
        const authData = await this.authData();
        if(!authData) {
            console.log('Unauthorized');
            history.push(url_for('home'));
        }
        console.log('authorized with', authData);
    })

    login = once(async ({grant_type, ...options}) => {
        let resp = await this.fetch(await this.urlFor('oauth.token'), {
            method: 'POST',
            body: JSON.stringify({grant_type, ...options})
        })
        let authData = await resp.json();
        console.log('logged in', authData);
        this._authData = authData;
        if(authData.refresh_token) {
            this._refresh_token = authData.refresh_token;
        }
        this._dispatch(A.loginArborian(authData));
        return authData;
    })

    logout = () => {
        this._authData = null;
        this._refresh_token = null;
    }

    authData = once(async () => {
        const now = moment();
        const expires = moment(fp.getOr(now, 'access_token_expires', this._authData));
        if(now >= expires) {
            return this.refresh();
        } else {
            return this._authData;
        }
    })

    refresh = once(() => {
        if(this._refresh_token) {
            return this.login({
                grant_type: 'refresh_token',
                refresh_token: this._refresh_token,
            })
        } else {
            return null;
        }
    })
}
