import React from 'react';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import {MuiThemeProvider, CssBaseline} from '@material-ui/core';

import reducer from './reducers';
import Router from './components/Router';
import {Provider as ApiProvider} from './api';
import theme from './theme'

import 'typeface-montserrat'

const store = createStore(
    reducer, /* preloadedState, */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default function App() {
  return (
    <Provider store={store}>
      <ApiProvider>
        <CssBaseline/>
        <MuiThemeProvider theme={theme}>
            <Router />
        </MuiThemeProvider>
      </ApiProvider>
    </Provider>
  );
}
