import React, {
    createContext, useEffect, useContext, useState,
} from 'react';
import {useDispatch} from 'react-redux';
import fp from 'lodash/fp';
import moment from 'moment';
import UriTemplate from 'uritemplate';

import {history, url_for} from './routes';
import * as A from './actions';
import {useStorage, once} from './utils';

export const RTOK_PREFIX = 'mhmid_rtok.';
export const Context = createContext();

export function Provider({children}) {
    let api = new MapHabitApi({
        root: process.env.REACT_APP_MH_API,
        name: 'maphabit',
    });
    return <Context.Provider value={api}>{children}</Context.Provider>
}

export const useRawMapHabitApi = () => useContext(Context);
export const useMapHabitApi = () => {
    let [value, setValue] = useState(null);
    let api = useContext(Context);

    useEffect(
        () => {
            api.ensureAuthorized().then(() => setValue(api));
        },
        [api]
    )
    return value;
}

class MapHabitApi {

    constructor({root, name}) {
        console.log('Building an API with root', {root, name});
        const [rtok, setRtok] = useStorage(sessionStorage, RTOK_PREFIX + name, null);
        this._dispatch = useDispatch();
        this._root = root;
        this._name = name;
        this._authData = null;   // cached version so we don't need to use the selector
        this._refresh_token = rtok;
        this._set_refresh_token = setRtok;
        this._directory = null;
    }

    directory = async () => {
        return {links:{
            login: this._root + '/v1/auth/login',
        }}
    }

    login = once(async ({username, password}) => {
        let url = await this.url_for('login');
        console.log("url", url);
        let resp = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-platform': 'web',
            },
            body: JSON.stringify({username, password})
        })
        if(!resp.ok) {
            console.error('MapHabitApi Error', resp);
            this._dispatch(A.notify({
                type: 'error',
                message: `MapHabitApi error: ${resp.status} ${resp.statusText}`,
            }))
        }
        let data = await resp.json();
        this._authData = data.data;
        console.log('got auth data', data);
        if(data.token.refresh_token) {
            this._set_refresh_token(data.token.refresh_token);
        }
        // this._dispatch(A.authComplete({maphabit: authData}));
        // if(grant_type !== 'refresh_token') {
        //     this._dispatch(A.notify({
        //         type: 'success',
        //         message: `Welcome, ${authData.username}`
        //     }))
        // }
        // if(authData.refresh_token) {
        //     this._set_refresh_token(authData.refresh_token)
        // }
        return this._authData;
    })

    ensureAuthorized = once(async () => {
        const authData = await this.authData();
        if(!authData) {
            console.log('Unauthorized');
            history.push(url_for('home'));
        }
        console.log('authorized with', authData);
    })

    fetch = async (url, options={}) => {
        let headers = fp.getOr({}, 'headers', options);

        headers = {
            'Content-Type': 'application/json',
            ...headers,
        };
        if(this._authData) {
            headers = {
                'Authorization': `Bearer ${this._authData.access_token}`,
                ...headers
            }
        }
        options = {
            credentials: 'include',
            ...options,
            headers,
        }
        try {
            let resp = await fetch(await url, options);
            if(!resp.ok) {
                throw(new Error(`MapHabitApi error: ${resp.status} ${resp.statusText}`))
            }
            return resp;
        } catch(e) {
            this._dispatch(A.notify({type: 'error', message: e.toString()}));
            throw(e);
        }
    }

    url_for = async(name, params={}) => {
        let dir = await this.directory();
        let tpl = UriTemplate.parse(dir.links[name]);
        let allParams = {
            ...fp.getOr({}, 'metadata', this._authData),
            ...params,
        }
        let url = tpl.expand(allParams);
        return new URL(url)
    }

    logout = () => {
        this._dispatch(A.logout());
        this._authData = null;
        this._set_refresh_token(null);
    }

    authData = once(async (refresh=true) => {
        const now = moment();
        const expires = moment(fp.getOr(now, 'access_token_expires', this._authData));
        if(refresh && now >= expires) {
            return this.refresh();
        } else {
            return this._authData;
        }
    })

    refresh = once(() => {
        if(this._refresh_token) {
            return this.login({
                grant_type: 'refresh_token',
                refresh_token: this._refresh_token,
            })
        } else {
            return null;
        }
    })

}