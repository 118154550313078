import {once} from '../utils';

import Api from './Api';

import * as A from '../actions';

export default class MaphabitApi extends Api {

    constructor(dispatch, {root, name}) {
        super(dispatch);
        this._root = root;
        this._name = name;
        this._authData = null;   // cached version so we don't need to use the selector
    }

    get _refresh_token() {
        try {
            return sessionStorage.getItem(this._name);
        } catch(error) {
            console.error('Error loading storage', error);
            return null;
        }
    }

    set _refresh_token(value) {
        if(value) {
            sessionStorage.setItem(this._name, value);
        } else {
            sessionStorage.removeItem(this._name, value);
        }
    }

    updateOptions(options) {
        return options; // {credentials: 'include', ...options}
    }

    updateHeaders(headers) {
        headers = super.updateHeaders(headers);
        headers = {
            'x-platform': 'web',
            ...headers,
        }
        if(this._authData) {
            headers = {
                'x-auth': this._authData.token.access_token,
                ...headers
            }
        }
        return headers;
    }

    async directory() {
        return {links:{
            login: this._root + '/v1/auth/login',
            token: this._root + '/v1/auth/token',
            master_data: this._root + '/v1/users/master',
            residents: this._root + '/v1/users/communities/residents',
        }}
    }

    async select(mhUser) {
        this._refresh_token = mhUser.refreshToken;
        return await this.refresh();
    }

    async login({username, password}) {
        let url = await this.urlFor('login');
        console.log("url", url);
        let resp = await this.fetch(url, {
            method: 'POST',
            body: JSON.stringify({username, password}),
        })
        return await this._handleAuthResponse(resp);
    }

    authData = once(async () => {
        if(!this._authData) {
            return this.refresh();
        } else {
            return this._authData;
        }
    })

    refresh = once(async () => {
        if(this._refresh_token) {
            let resp = await this.fetch(this.urlFor('token'), {
                method: 'POST',
                body: JSON.stringify({refresh_token: this._refresh_token})
            });
            return await this._handleAuthResponse(resp)
        } else {
            return null;
        }
    })

    logout() {
        this._authData = null;
        this._refresh_token = null;
    }

    async _handleAuthResponse(resp) {
        let data = await resp.json();
        console.log('mh auth resp', data);
        this._authData = data.data;
        if(data.data.token.refresh_token) {
            this._refresh_token = data.data.token.refresh_token;
        }
        this._dispatch(A.loginMapHabit(this._authData));
        return this._authData;
    }

}