import React from 'react';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import {useAsyncMemo} from '../utils';
import {useApi} from '../api';
import {url_for} from '../routes';

import BasePage from '../components/BasePage';
import PatientTable from '../components/PatientTable';


export default function PatientCollectionPage() {
    const api = useApi('arborian');
    const {state} = R.useLocation();
    const {facId} = R.useParams();

    const fac = useAsyncMemo(async () => {
        if(fp.get('fac', state)) return state.fac;
        if(!api) return null;
        let resp = await api.fetch(api.urlFor('pcc2.facility', {facId}))
        return await resp.json();
    }, [state, api])
    console.log('fac', fac)

    return (
        <BasePage title={`Patient Listing: ${fp.getOr('--', 'data.attributes.pcc.facilityName', fac)}`}>
            <p>Back to the <R.Link to={url_for('facilityCollection')}>facility listing</R.Link></p>
            <PatientTable fac={fac}/>
        </BasePage>
    );
}