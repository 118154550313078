import React, {useState} from 'react';

import {
    TextField, Button, makeStyles
} from '@material-ui/core';

import {useRawApi} from '../api';
import {useAsyncMemo} from '../utils';

import {useRawMapHabitApi} from '../maphabit';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 800,
    }
})


export default function MapHabitLoginForm() {
    const mh = useRawMapHabitApi();
    const classes = useStyles();
    const [values, setValues] = useState({
        username: '',
        password: '',
    })

    const onSubmit = e => {
        e.preventDefault();
        mh.login(values);
    }

    return (
        <form className={classes.form} onSubmit={onSubmit}>
            <TextField
                margin="dense" name="username" label="Username" value={values.username}
                onChange={e => setValues({...values, username: e.target.value})}
            />
            <TextField
                margin="dense" name="password" label="Password"
                type="password" value={values.password}
                onChange={e => setValues({...values, password: e.target.value})}
            />
            <Button variant="contained" color="primary" type="submit">Sign In</Button>
        </form>
    )
}