import React, {useEffect, useState} from 'react';
import * as R from 'react-router-dom';

import {useRawApi} from '../api';
import {useQuery, url_for} from '../routes';
import BasePage from '../components/BasePage';
import MaphabitUserSelector from '../components/MaphabitUserSelector';

export default function CallbackPage() {
    const q = useQuery();
    const api = useRawApi();
    const [content, setContent] = useState(null);
    const [initialized, setInitialized] = useState(false);

    const code = q.get('code');
    const state = q.get('state');
    useEffect(() => {
        async function run() {
            var aauth = null;
            try {
                aauth = await api.arborian.authData();
            } catch(e) { }

            if(!aauth) {
                console.log('Failed to get auth data with refresh, trying code...')
                aauth = await api.arborian.login({
                    grant_type: 'authorization_code',
                    code, state
                })
            }
            setContent(<MaphabitUserSelector />);
        }
        if(!initialized) {
            setInitialized(true);
            try {
                run()
            } catch(e) {
                console.error('Error in callback', e);
            }
        }
    }, [api, code, state]);
    return <BasePage title="Select User">{content}</BasePage>
}