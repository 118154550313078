import React from 'react';
import * as R from 'react-router-dom';
import {useSelector} from 'react-redux';

import {
    Drawer, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {url_for} from '../routes';


export default function NavDrawer({open, onClose}) {
    const authData = useSelector(state => state.auth);
    return (
        <Drawer open={open} onClose={onClose}>
            <List>
                <ListItem button component={R.Link} to={url_for('home')}>
                    <ListItemIcon><I.Home/></ListItemIcon>
                    <ListItemText primary="Home"/>
                </ListItem>
                {authData && [
                    <ListItem
                        key="facilityCollection"
                        button component={R.Link} to={url_for('facilityCollection')}
                    >
                        <ListItemIcon><I.Business/></ListItemIcon>
                        <ListItemText primary="Facility Listing"/>
                    </ListItem>,
                ]}
            </List>
        </Drawer>
    )
}