import React from 'react';

import {
    ButtonBase, makeStyles
} from '@material-ui/core';

import {useRawApi} from '../api';
import {useAsyncMemo} from '../utils';


const useStyles = makeStyles(theme => ({
    root: {
        width: 244,
        height: 50,
        backgroundImage: 'url(/s/images/pcc/signin-button-default-base.svg)',
        '&:hover': {
            backgroundImage: 'url(/s/images/pcc/signin-button-default-hover.svg)',
        },
        '&:active': {
            backgroundImage: 'url(/s/images/pcc/signin-button-default-pressed.svg)',
        }
    }
}));


export default function PccLoginButton() {
    const api = useRawApi();
    const classes = useStyles();
    const authorizeUrl = useAsyncMemo(api.arborian.authorizeUrl, [api]);

    return (
        <ButtonBase disableRipple classes={classes} href={authorizeUrl}
            alt="Sign in with PointClickCare"
        />
    )
}

