import React from 'react';
import * as R from 'react-router-dom';

import routes, {history} from '../routes';

import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import CallbackPage from '../pages/CallbackPage';

import FacilityCollectionPage from '../pages/FacilityCollectionPage';
import PatientCollectionPage from '../pages/PatientCollectionPage';
import PickListCollectionPage from '../pages/PickListCollectionPage';

import UserCollectionPage from '../pages/UserCollectionPage';

export default function Router() {
    return (
        <R.Router history={history}>
            <R.Switch>
                <R.Route exact path={routes.home.path} component={HomePage}/>
                <R.Route exact path={routes.login.path} component={LoginPage}/>
                <R.Route exact path={routes.callback.path} component={CallbackPage}/>

                <R.Route exact path={routes.facilityCollection.path} component={FacilityCollectionPage}/>
                <R.Route exact path={routes.patientCollection.path} component={PatientCollectionPage}/>
                <R.Route exact path={routes.pickListCollection.path} component={PickListCollectionPage}/>

                <R.Route exact path={routes.userCollection.path} component={UserCollectionPage}/>
            </R.Switch>
        </R.Router>
    )
}