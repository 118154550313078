import React, {useEffect, useCallback, useRef, useState} from 'react';
import fp from 'lodash/fp';

import MaterialTable from 'material-table';

import {useApi} from '../api';

export default function PickListTable({fac, options, mh_name, pcc_name, title}) {
    const api = useApi('arborian');
    const tableRef = useRef();
    const [list, setList] = useState(null);

    const columns = [
        {title: 'MapHabit Value', field: 'mh_id', lookup: fp.get(mh_name, options)},
        {title: 'Id', field: 'id', editable: 'never'},
        {title: 'Description', field: 'description', editable: 'never'},
        {title: 'Other', render: r => (
            <pre>
                {JSON.stringify(fp.get('metadata', r), null, 2)}
            </pre>
        )},
    ];

    const fetchData = useCallback(async () => {
        if(!api || !fac) return {data: [], page: 0, totalCount: 0};
        let url = new URL(fp.get('data.relationships.pickLists.links.related', fac));
        url.searchParams.set('filter[pcc.name]', pcc_name);
        let resp = await api.fetch(url);
        let data = await resp.json();
        setList(data.data[0])

        let default_option = {description: "Default", id: -1, metadata: {}, mh_id: undefined}
        let pcc_elements = data.data[0].attributes.pcc.elements
        pcc_elements.splice(0, 0, default_option)

        let maphabit = data.data[0].attributes.maphabit;
        let elements = fp.map(el => {
            let mval = fp.find(m => m.pcc_id === el.id, maphabit);
            let mh_id = fp.get('mh_id', mval);
            return {...el, mh_id}
        }, pcc_elements);
        return {
            data: elements,
            totalCount: elements.length,
            page: 0,
        }
    }, [api, fac, pcc_name])

    const refresh = useCallback(
        () => tableRef.current.onQueryChange(),
        [tableRef]
    );

    const onRowUpdate = async (newData, oldData) => {
        const fullList = [
            ...list.attributes.maphabit,
            {pcc_id: newData.id, mh_id: Number(newData.mh_id)},
        ];
        const newMapping = fp.uniqBy(el => el.pcc_id, fullList);
        const body = fp.set(
            'attributes.maphabit',
            newMapping,
            {type: list.type, id: list.id}
        );
        console.log('got update', body.attributes.maphabit);
        let r = await api.fetch(list.links.self, {
            method: 'PATCH',
            body: JSON.stringify({data: body})
        })
        console.log('Got result', r)
    }

    const onRowDelete = async oldData => {
        const mh_id = fp.get('mh_id', oldData);
        const id = fp.get('id', oldData);
        if(mh_id) {
            const newMapping = fp.flow(
                fp.get('attributes.maphabit'),
                fp.filter(m => m.pcc_id !== id)
            )(list);
            const body = fp.set(
                'attributes.maphabit',
                newMapping,
                {type: list.type, id: list.id}
            );
            console.log({list, oldData});
            console.log('got update', body.attributes.maphabit);
            let r = await api.fetch(list.links.self, {
                method: 'PATCH',
                body: JSON.stringify({data: body})
            })
            console.log('Got result', r)
        }
    }

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [tableRef, api, fac])

    return (
        <MaterialTable
            title={title}
            columns={columns}
            data={fetchData}
            tableRef={tableRef}
            options={{
                search: false,
                sorting: false,
                paging: false,
            }}
            editable={{onRowUpdate, onRowDelete}}
            actions={[
                {icon: 'refresh', tooltip: 'Refresh', isFreeAction: true, onClick: refresh},
            ]}
        />
    )

}