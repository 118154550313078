import fp from 'lodash/fp';
import UriTemplate from 'uritemplate';

import * as A from '../actions';

export default class Api {

    constructor(dispatch) {
        this._dispatch = dispatch;
    }

    async fetch(url, options={}) {
        let headers = fp.getOr({}, 'headers', options);
        headers = this.updateHeaders(headers);
        options = this.updateOptions({...options, headers});
        try {
            let resp = await fetch(await url, options);
            if(!resp.ok) {
                throw(new Error(`Api error: ${resp.status} ${resp.statusText}`))
            }
            return resp;
        } catch(e) {
            this._dispatch(A.notify({type: 'error', message: e.toString()}));
            throw(e);
        }
    }

    async urlFor(name, params={}) {
        let dir = await this.directory();
        let tpl = UriTemplate.parse(dir.links[name]);
        let allParams = {
            ...fp.getOr({}, 'metadata', this._authData),
            ...params,
        }
        let url = tpl.expand(allParams);
        return new URL(url)
    }

    async directory() {
        return {links: {}};
    }

    updateOptions(options) {
        return {credentials: 'include', ...options}
    }

    updateHeaders(headers) {
        return {'Content-Type': 'application/json', ...headers};
    }
}