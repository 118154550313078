import React, {useState, useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';

import {
    List, ListItem, ListItemText, ListItemIcon, Tooltip, IconButton, Icon,
    TextField,
    makeStyles
} from '@material-ui/core';


import * as A from '../actions';
import {useRawApi} from '../api';
import {url_for} from '../routes';


const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 800,
    },
    form: {
        // display: 'flex',
        // flexDirection: 'column',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // width: 200,
    },
}));


function UserSelectionList({options, onSelect, onAdd, onDelete}) {

    return (
        <List>
            {fp.map(ui => (
                <ListItem key={ui.userId} >
                    <ListItemIcon>
                        <Tooltip title="Unlink User">
                            <IconButton onClick={() => onDelete(ui)}>
                                <Icon>link_off</Icon>
                            </IconButton>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={ui.username}/>
                    <ListItemIcon>
                        <Tooltip title="Select this user">
                            <IconButton onClick={() => onSelect(ui)}>
                                <Icon>arrow_right</Icon>
                            </IconButton>
                        </Tooltip>
                    </ListItemIcon>
                </ListItem>
            ), options)}
            <MaphabitLoginForm onSubmit={onAdd}/>
        </List>
    )
}


function MaphabitLoginForm({onSubmit}) {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = useCallback(e => {
        e.preventDefault();
        onSubmit({username, password});
    }, [onSubmit, username, password]);

    return (
        <ListItem>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                    className={classes.textField} layout="dense"
                    name="username" label="Username" value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <TextField
                    className={classes.textField} layout="dense"
                    name="password" label="Password" type="password" value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <ListItemIcon>
                    <Tooltip title="Link new user">
                        <IconButton type="submit">
                            <Icon>link</Icon>
                        </IconButton>
                    </Tooltip>
                </ListItemIcon>
            </form>
        </ListItem>
    )
}


export default function MaphabitUserSelector() {
    const api = useRawApi();
    const classes = useStyles();
    let dispatch = useDispatch();
    let history = R.useHistory()
    const [user, setUser] = useState(null);

    const fetchUser = useCallback(async () => {
        let arborian = await api.arborian.authData();
        let url = await api.arborian.urlFor('mh2.user', {pccUserId: arborian.userId});
        let resp = await api.arborian.fetch(url);
        let user = await resp.json();
        setUser(user.data);
    }, [api, setUser]);

    const linkUser = async cred => {
        let mhData = await api.maphabit.login(cred);
        let mhUser = {
            userId: mhData.id,
            communityId: mhData.group_id,
            username: mhData.username,
            refreshToken: mhData.token.refresh_token,
        };
        let resp = await setMapHabitUsers(
            [...user.attributes.mhUsers, mhUser]
        );
        return await onSelect(mhUser);
    }

    const unlinkUser = async mhuser => {
        let resp = setMapHabitUsers(
            fp.filter(ui => ui.username !== mhuser.username, user.attributes.mhUsers)
        )
        return resp;
    }

    const setMapHabitUsers = async mhUsers => {
        let body = fp.set('attributes.mhUsers', mhUsers, user);
        let resp = await api.arborian.fetch(user.links.self, {
            method: 'PATCH',
            body: JSON.stringify({data: body}),
        })
        return await fetchUser();
    }

    const onSelect = async ui => {
        await api.select(ui);
        history.push(url_for('home'));
    }

    useEffect(() => {
        fetchUser();
    }, [api, fetchUser]);

    return (
        <div className={classes.container}>
            <UserSelectionList options={fp.get('attributes.mhUsers', user)}
                onSelect={onSelect} onAdd={linkUser} onDelete={unlinkUser}
            />
        </div>
    )


}