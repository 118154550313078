import React, {useCallback, useRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import MaterialTable from 'material-table';

import {useApi} from '../api';

const columns = [
    {
        title: 'User ID', field: 'id', type: 'numeric',
    },
]


export default function AuthorizedUserTable(props) {
    const api = useApi();
    const tableRef = useRef();
    const pccUserId = useSelector(fp.get('auth.userId'));
    const [pccUser, setPccUser] = useState(null);

    const fetchPccUser = useCallback(async () => {
        if(!api || !pccUserId) return;
        setPccUser(null);
        let resp = await api.fetch(api.urlFor('mh2.user', {pccUserId}));
        let user = await resp.json();
        setPccUser(user.data);
        return user.data;
    }, [api, pccUserId]);

    const onRowAdd = useCallback(async (newData) => {
        if(!api || !pccUser) return;
        const rel = fp.get('relationships.maphabitUsers', pccUser);
        await api.fetch(rel.links.self, {
            method: 'POST',
            body: JSON.stringify({
                data: [{type: 'MaphabitUser', id: newData.id}]
            })
        })
        await fetchPccUser();
    }, [api, pccUser, fetchPccUser]);

    const onRowDelete = useCallback(async (oldData) => {
        if(!api || !pccUser) return;
        const rel = fp.get('relationships.maphabitUsers', pccUser);
        await api.fetch(rel.links.self, {
            method: 'DELETE',
            body: JSON.stringify({
                data: [{type: 'MaphabitUser', id: oldData.id}]
            })
        })
        await fetchPccUser();
    }, [api, pccUser, fetchPccUser])

    useEffect(() => {
        fetchPccUser();
    }, [api, pccUserId, fetchPccUser]);

    return (
        <MaterialTable
            title="Authorized Users"
            columns={columns}
            data={fp.getOr([], 'relationships.maphabitUsers.data', pccUser)}
            tableRef={tableRef}
            options={{
                search: false,
                paging: false,
            }}
            editable={{onRowAdd, onRowDelete}}
            actions={[
                {icon: 'refresh', tooltip: 'Refresh', isFreeAction: true, onClick: fetchPccUser},
            ]}
            {...props}
        />
    )
}