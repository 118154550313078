import React, {useEffect, useCallback, useRef, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import moment from 'moment';

import MaterialTable from 'material-table';

import {useApi} from '../api';

import EditField from './NullableTableEditField';

const dateField = col => ({
    ...col,
    render: row => moment(fp.get(col.field, row)).format('YYYY-MM-DD'),
    filtering: false,
})

const attrName = col => col.field.split('.').slice(1).join('.');

export default function PatientTable({fac}) {
    const api = useApi('arborian');
    const mh = useApi('maphabit');
    const tableRef = useRef();
    const authData = useSelector(fp.get('auth.maphabit'));

    const [residents, setResidents] = useState([]);

    const resident_lookup = useMemo(() => {
        return fp.transform(
            (acc, r) => {
                acc[r.id] = `${r.last_name}, ${r.first_name} (DOB ${r.dob})`
            },
            {}, residents
        )
    }, [residents]);

    const columns = [
        // {title: 'Prefix', field: 'attributes.prefix'},
        {title: 'Resident', field: 'attributes.maphabit.mh_user_id', lookup: resident_lookup, filtering: false},
        {title: 'First', field: 'attributes.pcc.firstName', editable: 'never'},
        {title: 'Preferred', field: 'attributes.pcc.preferredName', filtering: false, editable: 'never'},
        // {title: 'Middle', field: 'attributes.pcc.middleName'},
        {title: 'Last', field: 'attributes.pcc.lastName', defaultSort: 'asc', editable: 'never'},
        // {title: 'Suffix', field: 'attributes.pcc.suffix'},
        dateField({title: 'Birth', field: 'attributes.pcc.birthDate', editable: 'never'}),
        dateField({title: 'Admission', field: 'attributes.pcc.admissionDate', editable: 'never'}),
        {title: 'Floor', field: 'attributes.pcc.floorDesc', filtering: false, editable: 'never'},
        {title: 'Unit', field: 'attributes.pcc.unitDesc', editable: 'never'},
        {title: 'Room', field: 'attributes.pcc.roomDesc', filtering: false, editable: 'never'},
        {title: 'Bed', field: 'attributes.pcc.bedDesc', filtering: false, editable: 'never'},
        {title: 'Gender', field: 'attributes.pcc.gender', filtering: false, editable: 'never'},
        {title: 'Ethnicity', field: 'attributes.pcc.ethnicityDesc', filtering: false, editable: 'never'},
    ];

    const fetchResidents = useCallback(async () => {
        if(!mh) return;
        let curList = [];
        let limit = 200;
        let page = 1;
        let url = await mh.urlFor('residents');
        url.searchParams.set('limit', limit);
        while(true) {
            url.searchParams.set('page', page);
            let resp = await mh.fetch(url);
            let data = await(resp.json());
            curList = [...curList, ...data.data.residents];
            setResidents(curList);
            if(data.meta.page >= data.meta.pages) {
                break
            } else {
                page++;
            }
        }
    }, [mh]);

    const fetchData = useCallback(async ({
        filters, orderBy, orderDirection, page, pageSize, search, totalCount,
    }) => {
        if(!api || !fac) return {data: [], page: 0, totalCount: 0};
        let url = new URL(fp.get('data.relationships.patients.links.related', fac))
        url.searchParams.set('page[limit]', pageSize);
        url.searchParams.set('page[offset]', page * pageSize);
        if(orderDirection === 'asc') {
            url.searchParams.set('sort', attrName(orderBy));
        } else if(orderDirection === 'desc') {
            url.searchParams.set('sort', '-' + attrName(orderBy))
        }
        fp.forEach(flt => {
            const k = attrName(flt.column);
            switch(flt.operator) {
                case '=':
                    url.searchParams.set(`filter[${k}]`, '^' + flt.value);
                    break;
                default:
                    console.error('Cannot handle filter:', flt);
            }
        }, filters);
        if(search) {
            url.searchParams.set('filter[_q]', search);
        }
        console.log(filters);

        let resp = await api.fetch(url);
        let patients = await resp.json();
        console.log('Got patients', patients);

        let rv = {
            data: patients.data,
            totalCount: patients.meta.total,
            page,
        }
        return rv;
    }, [api, fac]);

    const refresh = useCallback(
        () => tableRef.current.onQueryChange(),
        [tableRef]
    );

    const onRowUpdate = async (newData, oldData) => {
        console.log('update patient', {newData, oldData});
        const arb_auth = await api.authData();
        const mhUser = fp.find(
            mhUser => mhUser.user_id === authData.id,
            arb_auth.mh_users,
        )
        console.log('mhUser', mhUser);
	newData = fp.set(
	    'attributes.maphabit.mh_community_id',
	    mhUser.community_id,
	    newData
	)

        const body = fp.set(
            'attributes.maphabit',
            fp.get('attributes.maphabit', newData),
            {type: newData.type, id: newData.id}
        );
        console.log('got update', body.attributes.maphabit);
        let r = await api.fetch(oldData.links.self, {
            method: 'PATCH',
            body: JSON.stringify({data: body})
        })
        console.log('Got result', r)
    }

    const onUnlink = async row => {
        const body = fp.set(
            'attributes.maphabit',
            {mh_user_id: null},
            {type: row.type, id: row.id}
        );
        console.log('got update', body.attributes.maphabit);
        let r = await api.fetch(row.links.self, {
            method: 'PATCH',
            body: JSON.stringify({data: body})
        })
        console.log('Got result', r)
        refresh();
    }

    useEffect(() => {
        fetchResidents();
    }, [fetchResidents])

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [tableRef, api, fac])

    return (
        <MaterialTable
            title="Patient Listing"
            columns={columns}
            data={fetchData}
            tableRef={tableRef}
            components={{EditField}}
            options={{
                filtering: true,
            }}
            editable={{onRowUpdate}}
            actions={[
                {icon: 'refresh', tooltip: 'Refresh', isFreeAction: true, onClick: refresh},
                row => ({
                    icon: 'link_off', tooltip: 'Unlink patient', onClick: () => onUnlink(row),
                    hidden: row.attributes.maphabit.mh_user_id === null
                }),
            ]}
        />
    )

}
